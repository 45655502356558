'use client';
import RouteLink from '@/components/RouteLink';
import HomeIcon from 'public/assets/icons/home.svg';
import OffersIcon from 'public/assets/icons/discount.svg';
import ReferIcon from 'public/assets/icons/refer.svg';
import ContestIcon from 'public/assets/icons/trophy.svg';
import SettingsIcon from 'public/assets/icons/gear.svg';
import { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';
import {
  CONTEST_PAGE,
  HOME_PAGE,
  OFFERS_PAGE,
  REFERRALS_PAGE,
  SETTINGS_PAGE,
} from '@/constants/routes.const';
import { AndroidService } from '@/service/Android';
import { LANGUAGES_CONTENT } from '@/constants/language';
import { StorageService } from '@/service/StorageService';

import { cn } from '@/utils';
import useAuthContext from '@/context/authContext';

export default function BottomTab() {
  const { contestEnabled } = useAuthContext();

  const pathName = usePathname();
  useEffect(() => {
    handleRouterEvents();
  }, [pathName]);

  function handleRouterEvents() {
    if (pathName === HOME_PAGE) {
      AndroidService.clearBrowserHistory();
    }
  }
  return (
    <div className="fixed inset-0 top-auto flex items-center justify-center bg-black z-2 md:hidden">
      <div
        className={cn(
          'grid w-full',
          contestEnabled ? 'grid-cols-5' : 'grid-cols-4'
        )}
      >
        <RouteLink
          href={HOME_PAGE}
          title={LANGUAGES_CONTENT[StorageService.getLanguage()]['nav'].home}
          MenuIcon={HomeIcon}
        />
        <RouteLink
          href={OFFERS_PAGE}
          title={LANGUAGES_CONTENT[StorageService.getLanguage()]['nav'].offers}
          MenuIcon={OffersIcon}
        />
        <RouteLink
          href={REFERRALS_PAGE}
          title={LANGUAGES_CONTENT[StorageService.getLanguage()]['nav'].refer}
          MenuIcon={ReferIcon}
        />
        {contestEnabled && (
          <RouteLink
            href={CONTEST_PAGE}
            title={
              LANGUAGES_CONTENT[StorageService.getLanguage()]['nav'].contest
            }
            MenuIcon={ContestIcon}
          />
        )}
        <RouteLink
          href={SETTINGS_PAGE}
          title={
            LANGUAGES_CONTENT[StorageService.getLanguage()]['nav'].settings
          }
          MenuIcon={SettingsIcon}
        />
      </div>
    </div>
  );
}
